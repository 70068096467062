import React, { useState } from "react";
import CardTable from "./CardTable";

const MutualMatchQueueTable = ({ activeSession = {}, currentQueue = {}, eventUsers, groupCount = 0 }) => {
  const { sitters = [], queueMatrix = {} } = currentQueue;

  const movers = groupCount === 1 ? sitters : (currentQueue.movers || []);

  // Map mover IDs to their eventUsers data
  const moversData = movers.map(id => eventUsers.find(u => u.userID === id));

  // Map sitter IDs to their eventUsers data
  const sittersData = sitters.map(id => eventUsers.find(u => u.userID === id));

  // Prepare data for sitters and movers
  const allSitters = createTableData(sittersData, queueMatrix, true);
  const allMovers = createTableData(moversData, queueMatrix, false);
  let restOfMovers = [...allMovers];

  // Render the grid
  return (
    <div className="grid lg:grid-cols-4 gap-4 md:grid-cols-3 grid-cols-2 card-table-space text-white">
      {/* Render matched sitters and movers */}
      {allSitters.map((sitter, index) => {
        const matchedMover = findMoverForSitter(index, activeSession.currentRound, queueMatrix, moversData);

        if (!matchedMover && groupCount === 1) return;

        // If a mover was found, remove it from the allMovers
        if (matchedMover) {
          restOfMovers.splice(restOfMovers.findIndex(mover => mover.userID === matchedMover.userID), 1);
          if (groupCount === 1) {
            restOfMovers.splice(restOfMovers.findIndex(mover => mover.userID === sitter.userID), 1);
          }
        }
        return (
          <CardTable
            key={`sitter_${index}`}
            sitter={sitter}
            mover={matchedMover}
            table={sitter.table}
            activeSession={activeSession}
            groupCount={groupCount}
          />
        );
      })}

      {/* Render unmatched movers */}
      {restOfMovers.map((mover) => (
        <CardTable
          key={`mover_${mover.table}`}
          mover={mover}
          sitter={null}
          table={mover.table}
          activeSession={activeSession}
        />
      ))}
    </div>
  );
};

export default MutualMatchQueueTable;

// Creates data for either movers or sitters with matched status
const createTableData = (group, queueMatrix, isSitter) => {
  const matchedSet = new Set(
    Object.keys(queueMatrix).map((key) => Number(key.split('_')[isSitter ? 1 : 0]))
  );

  return group.map((person, index) => ({
    ...person,
    table: index + 1,
    matched: matchedSet.has(index),
  }));
};

// Finds the mover for a given sitter in a specific round
const findMoverForSitter = (sitterIndex, currentRound = 0, queueMatrix, movers) => {
  const adjustedRound = currentRound <= 1 ? 0 : currentRound - 1;

  const key = Object.keys(queueMatrix).find((k) => {
    const [round, sitter] = k.split('_').map(Number);
    return round === adjustedRound && sitter === sitterIndex;
  });

  return key ? movers[queueMatrix[key]] : null;
};