export default class MixerScheduler {
  constructor(initNumPlayers, queueMatrix = []) {
    this.numPlayers = initNumPlayers;
    this.queueMatrix = queueMatrix;
  }

  generateSchedule() {
    const numPlayers = this.numPlayers;
    const isOdd = numPlayers % 2;
    const totalPlayers = isOdd ? numPlayers + 1 : numPlayers;

    const players = [];
    for (let i = 0; i < totalPlayers; i++) {
      players.push(i < numPlayers ? i : null);
    }

    const rounds = totalPlayers - 1;
    const half = totalPlayers / 2;
    const schedule = [];

    for (let round = 0; round < rounds; round++) {
      const roundMatches = {};
      for (let i = 0; i < half; i++) {
        const home = players[i];
        const away = players[totalPlayers - 1 - i];
        if (home !== null && away !== null) {
          roundMatches[home] = away;
          roundMatches[away] = home;
        } else {
          if (home !== null) roundMatches[home] = null;
          if (away !== null) roundMatches[away] = null;
        }
      }
      schedule.push(roundMatches);
      const last = players.pop();
      players.splice(1, 0, last);
    }

    const matrix = [];
    for (let round = 0; round < schedule.length; round++) {
      const roundArray = [];
      for (let player = 0; player < numPlayers; player++) {
        roundArray.push(
          schedule[round][player] !== undefined ? schedule[round][player] : null
        );
      }
      matrix.push(roundArray);
    }

    this.queueMatrix = matrix;
    return matrix;
  }

  updateScheduleWithNewParticipants(roundNumber, newParticipants, numInitialPlayers) {
    const initialSchedule = this.queueMatrix;
    const totalPlayers = numInitialPlayers + newParticipants.length;

    const expandedSchedule = initialSchedule.map((round) => {
      const expandedRound = round.slice();
      for (let i = numInitialPlayers; i < totalPlayers; i++) {
        expandedRound.push(null);
      }
      return expandedRound;
    });

    const matchesPlayed = new Set();
    for (let round = 0; round < roundNumber; round++) {
      for (let player = 0; player < numInitialPlayers; player++) {
        const opponent = expandedSchedule[round][player];
        if (opponent !== null && player < opponent) {
          matchesPlayed.add(`${player}-${opponent}`);
        }
      }
    }

    const remainingMatches = [];
    for (let i = 0; i < totalPlayers; i++) {
      for (let j = i + 1; j < totalPlayers; j++) {
        const matchKey = `${i}-${j}`;
        if (!matchesPlayed.has(matchKey)) {
          remainingMatches.push([i, j]);
        }
      }
    }

    const updatedSchedule = expandedSchedule.slice(0, roundNumber);

    while (remainingMatches.length > 0) {
      const roundMatches = {};
      const scheduledPlayers = new Set();

      for (let i = 0; i < remainingMatches.length;) {
        const [player1, player2] = remainingMatches[i];

        if (!scheduledPlayers.has(player1) && !scheduledPlayers.has(player2)) {
          roundMatches[player1] = player2;
          roundMatches[player2] = player1;
          scheduledPlayers.add(player1);
          scheduledPlayers.add(player2);
          remainingMatches.splice(i, 1);
        } else {
          i++;
        }
      }

      for (let i = 0; i < totalPlayers; i++) {
        if (!scheduledPlayers.has(i)) {
          roundMatches[i] = null;
        }
      }

      const roundArray = [];
      for (let player = 0; player < totalPlayers; player++) {
        roundArray.push(
          roundMatches[player] !== undefined ? roundMatches[player] : null
        );
      }

      // Only add the round if it's not all nulls
      if (!roundArray.every((match) => match === null)) {
        updatedSchedule.push(roundArray);
      }
    }

    this.queueMatrix = updatedSchedule;
    this.numPlayers = totalPlayers;

    return updatedSchedule;
  }

  removeDuplicateMeetings(flattenedMatrix) {
    // Step 1: Convert matrix entries to a more manageable format  
    const meetings = Object.entries(flattenedMatrix).map(([key, value]) => {
      const [round, user1] = key.split('_');
      return {
        round: parseInt(round),
        pair: [parseInt(user1), parseInt(value)].sort((a, b) => a - b) // Sort to normalize pair order  
      };
    });

    const areDuplicatePairs = (pair1, pair2) => {
      const [user1A, user1B] = pair1;
      const [user2A, user2B] = pair2;
      return (user1A === user2B && user1B === user2A) || (user1A === user2A && user1B === user2B);
    };

    // Step 2: Group meetings by round  
    const meetingsByRound = meetings.reduce((acc, meeting) => {
      if (!acc[meeting.round]) {
        acc[meeting.round] = [];
      }
      acc[meeting.round].push(meeting.pair);
      return acc;
    }, {});

    // Step 3: Remove duplicates within each round  
    const uniqueMeetings = {};
    Object.entries(meetingsByRound).forEach(([round, pairs]) => {
      const uniquePairs = pairs.filter((pair, index) => {
        const isUnique = !pairs.some((otherPair, otherIndex) =>
          index > otherIndex && areDuplicatePairs(pair, otherPair)
        );
        return isUnique;
      });

      // Step 4: Convert back to original format  
      uniquePairs.forEach(([user1, user2]) => {
        uniqueMeetings[`${round}_${user1}`] = user2;
      });
    });

    return uniqueMeetings;
  }
}