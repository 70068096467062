import React, { useEffect, useState } from "react";
import { useEventStream } from "../context/EventStreamingContext";
import { Field, Label, Switch } from "@headlessui/react";
import { SessionMetrics } from "./SessionMetrics";
import {
  LinkButton,
  PrimaryButtonSmall,
  SecondaryButtonSmall,
  SecondaryDangerButtonSmall,
} from "../components/Button";
import QueueTable from "../../src/components/QueueTable";
import {
  rotateMoversQueue,
  generateQueue,
  regenerateQueue,
  removeRemovedUsersFromQueue,
  getRemovedUsers,
  getAddedUsers,
} from "../utils/queue";
import {
  generateMutualMatchQueue,
  regenerateMutualMatchQueue
} from "../utils/mutualMatchQueue";
import { Waitlist } from "./Waitlist";
import { db } from "../firebase";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import EventAPI from "../api/EventApi";
import { cloudFunctionsAPI } from "../firebase";
import Loader from "../components/Loader";
import Utility from "../utils/utility";
import { showToast } from "../utils/showToast";
import { useNavigate } from "react-router-dom";
import { RemovedList } from "./RemovedList";
import MutualMatchQueueTable from "../../src/components/MutualMatchQueueTable";
import MixerScheduler from "../utils/mixerSchedulerQueue"
import { flattenMatrix, reconstructMatrix } from "../utils/createMatrix";

export const QueueSection = () => {
  const navigate = useNavigate();
  const [lateComers, setLateComers] = useState(null);
  const [removedUsers, setRemovedUsers] = useState([]);
  const [newQueue, setNewQueue] = useState(null);
  const { eventData, loading, eventUsersProfile } = useEventStream();
  const [editEnabled, setEditEnabled] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [queueLength, setQueueLength] = useState(
    eventData.queue?.movers?.length || 0
  );

  const [event, setEvent] = useState({
    id: eventData?.id || null,
    queue: eventData?.queue || {},
    activeSession: eventData?.activeSession || {},
    currentQueue: eventData?.queue,
    movers: eventData?.movers,
    sitters: eventData?.sitters,
  });
  const callCloudFunction = async (functionName, params) => {
    const functionUrl = cloudFunctionsAPI.getUrl(
      functionName,
      cloudFunctionsAPI.locationID
    );

    const body = JSON.stringify(params);

    try {
      const res = await fetch(functionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      console.log(`Success: ${res}`);
      return res;
    } catch (error) {
      console.error("Error calling Firebase function:", error);
    }
  };
  const startSession = async () => {
    // Check if the event type is 1 and the queueMatrix is empty
    if (!eventData.queue) {
      alert("Save the queue before starting the event session.");
      return;
    }
    else if (Object.keys(event.currentQueue.queueMatrix).length === 0) {
      alert("Cannot start the event session: No matches found.");
      return;
    }

    const confirmStart = window.confirm(
      "Do you want to start the event? The event will start in 1 minute."
    );
    if (confirmStart) {
      setButtonLoading(true);
      const eventID = eventData?.id;
      const currentTime = new Date();
      const res = await callCloudFunction(
        "createScheduleToUpdateCurrentRound",
        {
          eventID,
          currentTime,
        }
      );
      setButtonLoading(false);
      if (res.status == 200) {
        showToast("Event session started successfully");
      } else {
        alert("Error starting event session: " + res.statusText);
      }
    }
  };

  const stopSession = async () => {
    if (window.confirm("Do you want to stop the event?")) {
      setButtonLoading(true);
      const params = {
        eventID: eventData?.id,
        taskID: eventData?.activeSession.taskID,
      };
      const res = await callCloudFunction("stopEventSession", params);
      setButtonLoading(false);
      if (res.status == 200) {
        showToast("Event session stopped successfully");
      } else {
        alert("Error starting event session: " + res.statusText);
      }
    }
  };
  // Helper functions
  const getQueueLength = (eventData) => {
    if (eventData.queue?.queueMatrix) {
      const queueMatrixKeys = Object.keys(eventData.queue.queueMatrix);
      return Math.max(...queueMatrixKeys.map(key => parseInt(key.split('_')[0]))) + 1;
    }
    return 0;
  };

  const updateEventState = (eventData) => ({
    id: eventData?.id || null,
    queue: eventData?.queue || {},
    activeSession: eventData?.activeSession || {},
    currentQueue: eventData?.queue,
    movers: eventData?.movers,
    sitters: eventData?.sitters,
  });
  // Effect for updating event state and queue length
  useEffect(() => {
    setEvent(updateEventState(eventData));
    setQueueLength(getQueueLength(eventData));
  }, [eventData]);

  // Effect for updating late comers and removed users
  useEffect(() => {
    if (!eventData?.queue) return;

    const { movers, sitters, queue } = eventData;
    const { movers: moversQueue, sitters: sittersQueue } = queue;

    if (eventData.eventType == 0 && eventData.groupCount == 1 && !sittersQueue) return;
    if (eventData.eventType = !0 && eventData.groupCount != 1 && (!moversQueue || !sittersQueue)) return;
    const effectiveMoversQueue = moversQueue || [];

    setLateComers(getAddedUsers(movers, sitters, effectiveMoversQueue, sittersQueue));
    setRemovedUsers(getRemovedUsers(movers, sitters, effectiveMoversQueue, sittersQueue));
    setQueueLength(getQueueLength(eventData));
  }, [eventData?.queue, eventData?.movers, eventData?.sitters]);

  const [queueUnsaved, setQueueUnsaved] = useState(false);
  const hasUsers = (group) => Array.isArray(group) && group.length > 0;

  const groupHasUsers = (group) =>
    group && (hasUsers(group.movers) || hasUsers(group.sitters));

  // Regenerate queue with late comers.
  const handleRegenerateQueue = async () => {
    let newQueue = { ...event.queue };
    if (groupHasUsers(lateComers) && newQueue) {
      try {
        if (eventData.eventType == 0 && eventData.groupCount == 1) {
          const incomingSitters = event.sitters.filter(uid => !event.queue.sitters.includes(uid));
          const sitters = [...event.queue.sitters, ...incomingSitters];

          const oldQueueMatrix = reconstructMatrix(newQueue.queueMatrix);
          const sittersCount = event.sitters.length - incomingSitters.length;

          const mixer = new MixerScheduler(0, oldQueueMatrix);
          mixer.updateScheduleWithNewParticipants(eventData.activeSession.currentRound, incomingSitters, sittersCount);

          const flattenedQueueMatrix = flattenMatrix(mixer.queueMatrix);
          const queueMatrix = mixer.removeDuplicateMeetings(flattenedQueueMatrix);

          newQueue = {
            at: serverTimestamp(),
            queueMatrix,
            queueMatrixRaw: flattenedQueueMatrix,
            sitters
          };
        }
        else {
          newQueue = await regenerateMutualMatchQueue(
            event,
            eventData.activeSession.currentRound,
            eventData
          );
        }
      } catch (error) {
        alert(error.message);
      }
    }

    if (groupHasUsers(removedUsers) && eventData.eventType === 0) {
      newQueue = removeRemovedUsersFromQueue(newQueue, removedUsers);
    }

    setNewQueue(newQueue);
    setQueueUnsaved(true);
  };

  const handleGenerateQueue = async () => {
    const movers = [...event.movers];
    const sitters = [...event.sitters];
    let newQueue;
    try {
      if (eventData.eventType == 0 && eventData.groupCount == 1) {

        const mixer = new MixerScheduler(sitters.length);
        mixer.generateSchedule()

        const flattenedQueueMatrix = flattenMatrix(mixer.queueMatrix);
        const queueMatrix = mixer.removeDuplicateMeetings(flattenedQueueMatrix);


        newQueue = {
          at: serverTimestamp(),
          queueMatrix,
          queueMatrixRaw: flattenedQueueMatrix,
          sitters
        };
      }
      else {
        newQueue = await generateMutualMatchQueue(
          movers,
          sitters,
          eventData
        );
      }
    } catch (error) {
      alert(error.message);
    }

    setQueueUnsaved(true);

    setEvent({
      ...event,
      queue: newQueue,
      currentQueue: newQueue,
    });
  };

  const deleteQueue = () => {
    if (window.confirm("Are you sure?")) {
      const docRef = doc(db, "events", event.id);
      updateDoc(docRef, {
        queue: null,
      });
      navigate(`/events/${event.id}/edit`);
    }
  };

  const saveQueue = async () => {
    const docRef = doc(db, "events", event.id);
    const finalQueue = newQueue || event.queue;
    try {
      // Create a single update object
      const updateObject = {
        queue: finalQueue
      };

      // Add table numbers to the update object using dot notation
      finalQueue.sitters?.forEach((userID, index) => {
        let userNumber = index + 1;
        const userNumberLabel = eventData.groupCount == 1 ? `badgeNumber` : 'table';
        if (eventData.groupCount === 1) {
          userNumber = index + 1;
        }
        updateObject[`eventUsers.${userID}.${userNumberLabel}`] = userNumber;
      });

      // Single update call
      await updateDoc(docRef, updateObject);

      // Update state
      if (newQueue) setNewQueue(null);
      setQueueUnsaved(false);

      return true;
    } catch (error) {
      console.error('Error updating queue and tables:', error);
      throw new Error('Failed to update queue and tables');
    }
  };


  if (loading) {
    return (
      <div>
        <Loader></Loader>
      </div>
    );
  }

  function convertSeconds(timeLeftInSec) {
    if (
      timeLeftInSec == undefined ||
      timeLeftInSec == null ||
      timeLeftInSec.length == 0
    )
      return "";

    const elapsedTime =
      (Date.now() - Utility.convertDate(event.activeSession.at).getTime()) /
      1000;
    const currentTimeLeft = timeLeftInSec - elapsedTime;


    if (Math.floor(currentTimeLeft) === 30) {
      playAlertSound();
    }
    // Play sound when approximately 30 seconds are left
    // if (currentTimeLeft <= 31 && currentTimeLeft >= 29) {
    //   playAlertSound();
    // }

    if (currentTimeLeft < 60) {
      return `${Math.floor(currentTimeLeft)} sec`;
    }

    const minutes = Math.floor(currentTimeLeft / 60);
    const remainingSeconds = Math.floor(currentTimeLeft % 60);
    return `${minutes}m ${remainingSeconds}s`;
  }

  // Function to play the alert sound
  function playAlertSound() {
    const audio = new Audio('/ship-bell-two-chimes.mp3'); // Replace with your sound file path
    audio.play()
      .catch(error => console.log('Error playing sound:', error));
  }

  const onUpdateMetric = async (
    field,
    oldValue,
    newValue,
    cancelExistingTask = false
  ) => {
    if (!Number.isNaN(newValue) && Number(newValue) > 0) {
      newValue = Number(newValue);
      if (oldValue === newValue) return;
    } else {
      return;
    }
    setNewQueue(null);
    await EventAPI.updateEvent(eventData.id, {
      [`activeSession.${field}`]: newValue,
    });

    if (cancelExistingTask) {
      const params = {
        eventID: eventData?.id,
        cancelExistingTask,
      };

      await callCloudFunction("updateCurrentRound", params);
    }
  };

  const increaseRound = (metric) => {
    adjustRound(metric + 1);
  };
  const increaseTimeLeft = (metric) => {
    adjustTimeLeft(metric + 60);
  };

  const decreaseRound = (metric) => {
    adjustRound(metric - 1);
  };
  const decreaseTimeLeft = (metric) => {
    adjustTimeLeft(metric - 60);
  };

  const adjustTimeLeft = (newValue) => {
    onUpdateMetric("timeLeft", event.activeSession?.timeLeft, newValue, true);
    setEditEnabled(false);
  };
  const adjustRound = (newValue) => {
    onUpdateMetric("currentRound", event.activeSession?.currentRound, newValue);
    setEditEnabled(false);
  };


  const showStartButton = (() => {
    // First condition
    if (!eventData.queue) {
      return false;
    }

    // Second condition with null checks
    if (!event.currentQueue || !event.currentQueue.queueMatrix ||
      Object.keys(event.currentQueue.queueMatrix).length === 0) {
      return false;
    }

    // Final condition
    return event.currentQueue && !event.activeSession?.taskID;
  })();

  const hasNewConsents = (() => {
    if (!eventData.eventUsers?.lastUpdated || !eventData.queue?.at) {
      return false
    }

    // Convert to Date objects based on their type
    const lastUpdatedDate = eventData.eventUsers.lastUpdated.toDate
      ? eventData.eventUsers.lastUpdated.toDate()
      : new Date(eventData.eventUsers.lastUpdated);

    const queueAtDate = eventData.queue.at.toDate
      ? eventData.queue.at.toDate()
      : new Date(eventData.queue.at);

    // Validate the dates
    if (isNaN(lastUpdatedDate) || isNaN(queueAtDate)) {
      throw new Error("One of the dates is invalid");
    }

    // Compare the dates
    if (lastUpdatedDate > queueAtDate) {
      return true;
    } else if (lastUpdatedDate < queueAtDate) {
      return false;
    } else {
      return false;
    }
  })()



  if (loading) {
    return (
      <div>
        <Loader></Loader>
      </div>
    );
  }

  return (
    <div className={`${eventData.isCompleted ? "opacity-50" : ""}`}>
      <UserLists
        lateComers={lateComers}
        removedUsers={removedUsers}
        eventUsersProfile={eventUsersProfile}
        groupHasUsers={groupHasUsers}
        eventData={eventData}
      />

      {event.activeSession && Object.keys(event.activeSession).length !== 0 && (
        <div>
          {event.activeSession?.currentRound >= 0 && (
            <EditSwitch editEnabled={editEnabled} setEditEnabled={setEditEnabled} />
          )}
          <div className="justify-evenly grid grid-cols-2 gap-4">
            <SessionMetrics
              editEnabled={editEnabled}
              name={`Round`}
              metric={event.activeSession?.currentRound}
              increaseMetric={increaseRound}
              decreaseMetric={decreaseRound}
              formatMetric={(metric) => `${metric} / ${queueLength}`}
              loading={loading}
            />
            <SessionMetrics
              editEnabled={editEnabled}
              name={"Time"}
              metric={event.activeSession?.timeLeft}
              increaseMetric={increaseTimeLeft}
              decreaseMetric={decreaseTimeLeft}
              formatMetric={convertSeconds}
              loading={loading}
              renderEverySec={true}
              reRenderOnThisValueChange={event.activeSession?.at}
            />
          </div>
        </div>
      )}

      <QueueDisplay event={event} eventData={eventData} newQueue={newQueue} />

      <div className="mt-6 md:flex items-center justify-between">
        {!queueUnsaved && event.currentQueue ? (
          <LinkButton onClick={deleteQueue}>Delete queue</LinkButton>
        ) : (
          <p></p>
        )}

        <div className="flex items-center gap-x-6 mt-2 lg:mt-0">
          <QueueControls
            queueUnsaved={queueUnsaved}
            event={event}
            handleRegenerateQueue={handleRegenerateQueue}
            handleGenerateQueue={handleGenerateQueue}
            hasNewConsents={hasNewConsents}
            eventData={eventData}
            saveQueue={saveQueue}
          />
          <SessionControls
            event={event}
            startSession={startSession}
            stopSession={stopSession}
            buttonLoading={buttonLoading}
            eventData={eventData}
            showStartButton={showStartButton}
          />
        </div>
      </div>
    </div>
  );
};

// Queue Management Components
const QueueControls = ({ queueUnsaved, event, handleRegenerateQueue, handleGenerateQueue, hasNewConsents, eventData, saveQueue }) => (
  <div className="flex items-center gap-x-6  lg:mt-0">
    {queueUnsaved && event.currentQueue && (
      <SecondaryButtonSmall onClick={saveQueue}>
        Save new queue
      </SecondaryButtonSmall>
    )}

    {!eventData.isCompleted && (
      <SecondaryButtonSmall
        onClick={event.currentQueue && eventData.activeSession?.taskID ? handleRegenerateQueue : handleGenerateQueue}
      >
        <div className="flex justify-around gap-1">
          {event.currentQueue && eventData.activeSession?.taskID ? "Re-generate queue" : "Generate queue"}
          {hasNewConsents && (
            <span className="relative flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-primary"></span>
            </span>
          )}
        </div>
      </SecondaryButtonSmall>
    )}
  </div>
);

const SessionControls = ({ event, startSession, stopSession, buttonLoading, eventData, showStartButton }) => (
  <>
    {showStartButton && (
      <PrimaryButtonSmall onClick={startSession} loading={buttonLoading}>
        Start Event Session
      </PrimaryButtonSmall>
    )}
    {event.currentQueue && event.activeSession?.taskID && !eventData.isCompleted && (
      <SecondaryDangerButtonSmall onClick={stopSession} loading={buttonLoading}>
        Stop event
      </SecondaryDangerButtonSmall>
    )}
    {event.currentQueue && event.activeSession?.taskID && eventData.isCompleted && (
      <p className="text-gray-400 text-center">This event has ended</p>
    )}
  </>
);

const EditSwitch = ({ editEnabled, setEditEnabled }) => (
  <Field className="flex items-center justify-end mb-2">
    <Label as="span" className="mr-2 text-sm">
      <span className="text-gray-500 text-sm">Edit</span>
    </Label>
    <Switch
      checked={editEnabled}
      onChange={setEditEnabled}
      className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none data-[checked]:bg-primary"
    >
      <span
        aria-hidden="true"
        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
      />
    </Switch>
  </Field>
);

const QueueDisplay = ({ event, eventData, newQueue }) => (
  <>
    {event.currentQueue && Object.keys(event.currentQueue).length !== 0 ? (
      <div className={`mt-6`}>
        <MutualMatchQueueTable
          activeSession={event.activeSession}
          currentQueue={event.currentQueue}
          eventUsers={Object.values(eventData.eventUsers)}
          groupCount={eventData.groupCount}
        />
      </div>
    ) : (
      <p className="text-gray-400 mt-3">No queue exists</p>
    )}
    {newQueue && (
      <div className={`mt-6`}>
        <h1 className="text-lg font-bold mb-4">New Queue</h1>
        <MutualMatchQueueTable
          activeSession={event.activeSession}
          currentQueue={newQueue}
          eventUsers={Object.values(eventData.eventUsers)}
          groupCount={eventData.groupCount}
        />
      </div>
    )}
  </>
);

const UserLists = ({ lateComers, removedUsers, eventUsersProfile, groupHasUsers, eventData }) => (
  <div className="grid lg:grid-cols-2 gap-4">
    {groupHasUsers(lateComers) && (
      <div className="mb-4">
        <h1 className="w-full text-center my-2 text-gray-500">
          Waiting to Join...
        </h1>
        <Waitlist lateComers={lateComers} eventUsersProfile={eventUsersProfile} />
      </div>
    )}
    {groupHasUsers(removedUsers) && (
      <div className="mb-4">
        <h1 className="w-full text-center my-2 text-gray-500">
          Removed users
        </h1>
        <RemovedList removedUsers={removedUsers} eventUsers={eventData?.eventUsers} />
      </div>
    )}
  </div>
);

